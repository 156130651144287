<template>
  <v-app>
    <v-row align="center">
      <v-col cols="10" class="mx-auto">
        <h2 class="display-2">CONTACT US</h2>
        <p class="mb-5">
          If you have any complaints, inquiries or feedback about the platform,
          please fill in the form below and we will get back to you.
        </p>
        <v-col cols="7" class="pl-0">
          <v-form>
            <v-text-field
              v-model="name"
              label="Name"
              color="green darken-4"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="Email or Phone number"
              color="green darken-4"
              outlined
              dense
            ></v-text-field>
            <v-select
              label="Category (Please select one)"
              color="green darken-4"
              outlined
              dense
            ></v-select>
            <v-textarea
              v-model="feedback"
              label="Please type your feedback here"
              counter
              maxlength="120"
              outlined
            ></v-textarea>
            <v-btn medium color="green darken-4" class="white--text px-6">
              Send
              <v-icon small class="ml-3">mdi-send</v-icon>
            </v-btn>
          </v-form>
        </v-col>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {
      name: "",
      email: "",
      feedback: "",
    };
  },
};
</script>
